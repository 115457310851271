import React from "react";
import ContentWrapper from "components/content-wrapper";
import TicketShop from "components/ticket-shop";
import SEO from "components/seo";

const ManchesterPage = () => {
  return (
    <ContentWrapper>
      <SEO title="Freshers Events Manchester" />
      <div>
        <h1>MANCHESTER FRESHERS EVENTS</h1>
        <div style={{ position: "relative", width: "680px", maxWidth: "100%" }}>
          <TicketShop
            scriptString={`<script src="https://web-cdn.fixr.co/scripts/fixr-shop-widget.v1.min.js" data-fixr-shop-id="d2649f1f-35ed-485f-9e11-22e2db565930" data-fixr-theme="dark"></script>`}
          ></TicketShop>
        </div>
      </div>
    </ContentWrapper>
  );
};

export default ManchesterPage;
